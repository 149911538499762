import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Eventpeople() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="Eventpeople" style={{ minHeight: "100vh" }}></section>

      <section className="mt-4" style={{ minHeight: "80vh" }}>
        <div className="container  mb-3">

          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <h4 className=" text-center border-bottom border-3 border-danger fw-bold mt-3">
                WHAT YOU DON'T KNOW, COULD HURT YOU.
              </h4></div>
          </div>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 mt-3">
              <p className="text-center">
                Education and Training is the best way to prevent human
                trafficking. People, Not Products is an awareness seminar on
                human trafficking, internet safety, addiction, recovery and law
                enforcement technologies with experts from local, national and
                international organizations.
              </p>
              <p className="text-center">
                We want Chattanooga to be the safest city on the planet for all
                age groups. We want to eradicate human trafficking because every
                life deserves dignity and respect.
              </p>
              {/* <p className="text-center">
                This seminar will provide tools and information for students,
                teachers, educators, professionals in all careers and the
                general public.Keynote address will be<span className="fw-bolder"> Ms. Talia Hansen</span>, a
                victorious survivor of the <span className="fw-bolder">"Disappeared Children"</span>  from El
                Salvador's Civil War.
              </p> */}
              <div className="row d-flex justify-content-center mt-4 ">
                <div className="col-md-12 ">
                  <div className="card " style={{ backgroundColor: "#1A1E27" }}>
                    <p className="text-white text-center mt-3 mb-1 ">
                      <span className="fw-bolder">When : </span>
                    Saturday, September 28, 2024
                    </p>
                    <p className="text-white text-center mb-1 ">
                      <span className="fw-bolder"> Where : </span>
                     Chattanooga State Community College
                    </p>
                    {/* <p className="text-white text-center mb-1 ">
                      <span className="fw-bolder"> Who can attend : </span>
                      Teens aged 15 and over whose parents believe they are
                      mature enough to process the information are welcome to
                      attend
                    </p> */}
                    <p className="text-white text-center mb-1  ">
                      <span className="fw-bolder"> Registration : </span>
                      Includes access to vendor booths, swag materials and boxed lunch.
                    </p>
                    <p className="text-white text-center mb-1 ">
                      <span className="fw-bolder"> SPONSORSHIPS  : </span>
                      Please email us at events@lovewithoutreason.org for more information.
                    </p>
                    <p className="text-white text-center  ">
                      <span className="fw-bolder">  VENDORS : </span>
                      Please email us for more information.
                    </p>
                    <Link to='https://allevents.in/chattanooga/people-not-products-2024/200027147285717' target='_blank' className='btn btn-danger mx-auto mt-2 mb-4'>Register</Link>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-3">
              <h4 className=" text-center border-bottom border-3 border-danger fw-bold mt-4">
                ABOUT THE EVENT
              </h4></div>
          </div>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-10 mt-3">
              <h4 className="text-center fw-bolder ">People Not Products, 2024</h4><br />
              <h5 className="text-center fw-bolder">What you don't know could hurt you.</h5><br />
              <p className="india-text">Education and Training is the best way to prevent human trafficking. People Not Products is an awareness seminar on human trafficking internet safety addiction recovery and law enforcement technologies with experts from local national and international organizations.</p>
              <p className="india-text">We want Chattanooga to be the safest city on the planet for all age groups. We want to eradicate human trafficking because every life deserves dignity and respect.</p>
              <p className="india-text">This seminar will provide tools and information for students teachers educators professionals in all careers and the general public.</p>
              {/* <p className="india-text"> <span className="fw-bolder text-danger">PARENTAL DISCRETION IS DEFINITELY ADVISED: </span>
                Although we wish it wasn't so teens pre-teens elementary-aged children and babies are indeed the prime targets for specific types of trafficking particularly sex trafficking; so it is VERY important to educate them. However all of the ways in which they are targeted and exploited can be quite horrifying and the People Not Products event isn't aimed at children. So please know that some of the information presented at People Not Products can be quite disturbing. We absolutely advise parental discretion.</p> */}
              {/* <p className="india-text"><span className="fw-bolder text-danger">TEENS: </span>

                Teens aged 15 and over whose parents believe they are mature enough to process the information are welcome to attend and/or to volunteer.</p>
              <p className="india-text">
                <span className="fw-bolder text-danger">CHILDREN: </span>

                The spoken and written material will NOT be aimed at children or young teens and will not be filtered as though those age groups were present. So we do not encourage parents to bring young children to attend with them. This said each parent knows their child best. So parents use discretion on bringing them. Having made this advisory to parents we do encourage you to process and filter the knowledge and understanding that YOU gain from the presentations and please discuss and process the information with them as most appropriate to your own child(ren).</p> */}

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Eventpeople };
